import "./category.css";
import { Link } from "react-router-dom"; // import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faThumbsUp } from '@fortawesome/free-solid-svg-icons';

const Category = () => {
  return (
    //this section is for the category section in the homepage

    <div>
      <h1>Category</h1>

      <div className="car">
        <Link to="/resturant">
          <div className="img-txt">
            <img src="/assets/resturant.png" alt="" />
            <p>Resturant</p>
          </div>
        </Link>
        <Link to="/hotel">
          <div className="img-txt">
            <img src="/assets/hotel.png" alt="" />
            <p>Hotel</p>
          </div>
        </Link>
        <Link to="/pharmarcy">
          <div className="img-txt">
            <img src="/assets/pharmarcy.png" alt="" />
            <p>Pharmarcy</p>
          </div>
        </Link>
        <Link to="/ngo">
          <div className="img-txt">
            <img src="/assets/ngo.png" alt="" />
            <p>NGO</p>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default Category;
