import { Modal } from "flowbite-react";
import { useEffect } from "react";
import {
  HiOutlineCheckCircle,
  HiOutlineExclamationCircle,
} from "react-icons/hi";

export default function Toast({ type, message, isOpen, setIsOpen }) {
  useEffect(() => {
    if (isOpen) {
      const timer = setTimeout(() => {
        setIsOpen(false);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [isOpen, setIsOpen]);

  const Icon =
    type === "success" ? HiOutlineCheckCircle : HiOutlineExclamationCircle;
  const color = type === "success" ? "text-green-400" : "text-red-400";

  return (
    <Modal show={isOpen} size="md" popup>
      <Modal.Body>
        <div className="flex items-center justify-center h-full">
          <div className="flex flex-col items-center justify-center">
            <Icon
              className={`mx-auto mb-4 h-14 w-14 ${color} dark:text-gray-200`}
            />
            <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
              {message}
            </h3>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
