// eslint-disable-next-line no-unused-vars
import React from "react";
// import CardTest from "./components/Card-components/Card-test";
// import Find from "./Pages/Find";
// import ResturantHompage from "./Pages/ResturantHompage";
// import "flowbite";
import Routess from "./Routes/Routes"; // import CardSlider from "./components/Card-slider";
// import Map from "./components/Map";
// import SearchCard from "./components/Card-components/SearchCard";
// import Login from "./components/Auth/Login";
// import SignUp from "./components/Auth/Signup";
// import Otp from "./components/Auth/Otp";
// import HomePage from "./Pages/HomePage";
import { AuthProvider } from "./auth/AuthContext";

const App = () => {
  return (
    <div>
      {/* <HomePage /> */}
      {/* <Login /> */}
      {/* <SignUp /> */}
      {/* <Find /> */}
      {/* <ResturantHompage /> */}
      {/* <SearchCard /> */}
      {/* <CardSlider /> */}

      <AuthProvider>
        <Routess />
      </AuthProvider>
    </div>
  );
};

export default App;
