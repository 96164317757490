// import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomePage from "../Pages/HomePage";
import LoginHomePage from "../Pages/LoginHomePage";
import ResturantHompage from "../Pages/ResturantHompage";
import PublicRoute from "../auth/PublicRoute"; // import ResturantHompage from "../Pages/ResturantHompage";
import Login from "../components/Auth/Login";
import SignUp from "../components/Auth/Signup";
import Otp from "../components/Auth/Otp";
import Find from "../Pages/Find";
import HotelHompage from "../Pages/HotelHompage";
import Pharmarcy from "../Pages/Pharmarcy";
import Ngo from "../Pages/Ngos";
import ForgetPassword from "../components/Auth/ForgetPassword";
import ResetPassword from "../components/Auth/ResetPassword";
import ReviewCard from "../components/ReviewCard";
import EditAccount from "../components/accountsettingcomponents/EditAccount";
import MainFavoritePage from "../Pages/MainFavoritePage";
import NewSidebar from "../components/nav-component/newSidebar";
import PrivateRoute from "../auth/PrivateRoute";
import SharedRoute from "../auth/SharedRoute";
import Details from "../Pages/Details";

const Routess = () => {
  return (
    <div>
      <Router>
        <Routes>
          {/*Public Route*/}
          <Route path="/" element={<PublicRoute />}>
            <Route index element={<HomePage />} />
          </Route>
          <Route path="/login" element={<PublicRoute />}>
            <Route index element={<Login />} />
          </Route>
          <Route path="/signup" element={<PublicRoute />}>
            <Route index element={<SignUp />} />
          </Route>
          <Route path="/find" element={<PublicRoute />}>
            <Route index element={<Find />} />
          </Route>
          {/*<Route path="/ngo" element={<PublicRoute />}>*/}
          {/*  <Route index element={<Ngo />} />*/}
          {/*</Route>*/}
          <Route path="/forgotpassword" element={<PublicRoute />}>
            <Route index element={<ForgetPassword />} />
          </Route>
          <Route path="/resetpassword" element={<PublicRoute />}>
            <Route index element={<ResetPassword />} />
          </Route>
          {/*<Route path="/loginhomepage" element={<PublicRoute />} >*/}
          {/*    <Route index element={<LoginHomePage />} />*/}
          {/*</Route>*/}
          <Route path="/sidebar" element={<PublicRoute />}>
            <Route index element={<NewSidebar />} />
          </Route>
          {/*Shared Route*/}
          <Route path="/ngo" element={<SharedRoute />}>
            <Route index element={<Ngo />} />
          </Route>
          <Route path={`/details/:id`} element={<SharedRoute />}>
            <Route index element={<Details />} />
          </Route>
          <Route path="/resturant" element={<SharedRoute />}>
            <Route index element={<ResturantHompage />} />
          </Route>
          <Route path="/hotel" element={<SharedRoute />}>
            <Route index element={<HotelHompage />} />
          </Route>
          <Route path="/otp" element={<SharedRoute />}>
            <Route index element={<Otp />} />
          </Route>
          <Route path="/pharmarcy" element={<SharedRoute />}>
            <Route index element={<Pharmarcy />} />
          </Route>
          {/*Private Route*/}
          <Route path="/mainfavoritepage/:name" element={<PrivateRoute />}>
            <Route index element={<MainFavoritePage />} />
          </Route>
          <Route path="/loginhomepage" element={<PrivateRoute />}>
            <Route index element={<LoginHomePage />} />
          </Route>
          <Route path={`review/:id`} element={<PrivateRoute />}>
            <Route index element={<ReviewCard />} />
          </Route>
          <Route path="/editaccount" element={<PrivateRoute />}>
            <Route index element={<EditAccount />} />
          </Route>
          s
        </Routes>
      </Router>
    </div>
  );
};

export default Routess;
