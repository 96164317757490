// eslint-disable-next-line no-unused-vars
import React, {useEffect, useState} from "react";
import "./nav.css";
import Dropdown from "../Dropdown";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBars, faTimes} from "@fortawesome/free-solid-svg-icons";
import Sidebar from "./Sidebar";
import {Link} from "react-router-dom";
import EditAccountModal from "../accountsettingcomponents/EditAccountModal";
import Buttons from "./Buttons";
import {useAuth} from "../../auth/AuthContext";
import axios from "axios";
import API_BASE_URL from "../../auth/apiConfig";
// import EditAccountModal from "../accountsettingcomponents/EditAccountModal";

const Navbar = () => {
  //  hamburger menu state
  const [userProfile, setUserProfile] = useState({});
  const [show, setShow] = useState(false);
  const {
    actions: { logout, isTokenExpired },
  } = useAuth();

  useEffect(() => {
    if (isTokenExpired() === true) {
      return;
    }
    axios
      .get(`${API_BASE_URL}/user/profile`, {
        headers: {
          Authorization: `Bearer ${localStorage?.getItem("token")}`,
        },
      })
      .then((response) => {
        setUserProfile(response.data); // Update the user's profile with the response data
      })
      .catch((error) => {
        console.error("Error fetching user profile:", error);
      });
  }, []);

  const handleClick = () => {
    setShow(!show);
  };

  const handleLogout = () => {
    logout();
  };

  let isLogedin = false;

  return (
    <div className="nav">
      <Link to="/" className="logo">
        <img src="/assets/loggo.jpg" alt="" style={{ width: 84, height: 79 }} />
      </Link>

      <div>
        <Dropdown />
      </div>

      <div className="buttons">
        {isTokenExpired() === false ? (
          <div className="account">
            <img
              src={userProfile.profile_pic_url || "/assets/mainaccount.png"}
              alt="Profile"
            />
            <div className="account-info">
              <EditAccountModal />
            </div>
          </div>
        ) : (
          <Buttons />
        )}
      </div>

      <div onClick={handleClick} className="side">
        {show === true ? (
          <FontAwesomeIcon icon={faTimes} color="white" className="icon" />
        ) : (
          <FontAwesomeIcon icon={faBars} color="white" className="icon" />
        )}
      </div>

      {show && <Sidebar />}
    </div>
  );
};

export default Navbar;
