// import styled from "styled-components";
// import { useState } from "react";
// import axios from "axios";
// import { useNavigate } from "react-router-dom";
// import Toast from "../Toast";
//
// const ResetPassword = () => {
//   const navigate = useNavigate();
//   const [isOpen, setIsOpen] = useState(false);
//   const [message, setMessage] = useState("");
//   const [type, setType] = useState("success");
//   const [formData, setFormData] = useState({
//     otp: "",
//     new_password: "",
//     confirm_password: "",
//   });
//
//   const handleChange = (e) => {
//     setFormData({
//       ...formData,
//       [e.target.id]: e.target.value,
//     });
//   };
//
//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     try {
//       const response = await axios.post(
//         "http://44.222.48.171:8080/user/forget-password/reset/",
//         formData,
//       );
//       console.log(response.data);
//       const { message } = response.data;
//       if (message === "Password successfully reset") {
//         console.log("Password reset successfully");
//         setIsOpen(true);
//         setType("success");
//         setMessage("Check your Phone for OTP");
//
//         navigate("/login");
//       }
//       // handle success
//     } catch (error) {
//       setType("error");
//       setMessage("Something went wrong");
//       setIsOpen(true);
//       console.error(error);
//       // handle error
//     }
//   };
//   return (
//     <Div>
//       <center>
//         <div className="reset-password-container">
//           <p className="reset-password-title">Reset Password</p>
//           <form onSubmit={handleSubmit}>
//             <div className="input-container">
//               <label htmlFor="otp">Enter OTP</label> <br />
//               <input
//                 type="text"
//                 id="otp"
//                 onChange={handleChange}
//                 value={formData.otp}
//               />
//             </div>
//             <div className="input-container">
//               <label htmlFor="password">New Password</label> <br />
//               <input
//                 type="password"
//                 id="password"
//                 onChange={handleChange}
//                 value={formData.password}
//               />
//             </div>
//
//             <div className="input-container">
//               <label htmlFor="confirmPassword">Confirm Password</label> <br />
//               <input
//                 type="password"
//                 id="confirmPassword"
//                 onChange={handleChange}
//                 value={formData.confirmPassword}
//               />
//             </div>
//
//             <button type="submit" className="reset-password-button">
//               Submit
//             </button>
//           </form>
//
//           {/*<Toast type={type} message={message} isOpen={isOpen} />*/}
//           {isOpen && (
//             <Toast
//               type={type}
//               message={message}
//               isOpen={isOpen}
//               setIsOpen={setIsOpen}
//             />
//           )}
//         </div>
//       </center>
//     </Div>
//   );
// };
//
// export default ResetPassword;

import styled from "styled-components";
import { useEffect, useState } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import Toast from "../Toast";
import API_BASE_URL from "../../auth/apiConfig";

const ResetPassword = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [type, setType] = useState("success");
  const [formData, setFormData] = useState({
    otp: "",
    new_password: "",
    confirm_password: "",
  });

  useEffect(() => {
    // Check if the previous route was 'forgetpassword'
    console.log(location.pathname);
    // if (location.pathname !== "/forgotpassword") {
    //   // If not, redirect to the home route
    //   navigate("/");
    // }
    console.log(location.state?.from);
    if (location.state?.from !== "/forgotpassword") {
      navigate("/");
    }
  }, [location, navigate]);
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if the new password and the confirmed password match
    if (formData.new_password !== formData.confirm_password) {
      setType("error");
      setMessage("The new password and the confirmed password do not match.");
      setIsOpen(true);
      return;
    }

    try {
      const response = await axios.post(
        `${API_BASE_URL}/user/forget-password/reset/`,
        formData,
      );
      console.log(response.data);
      const { message } = response.data;
      if (message === "Password successfully reset") {
        console.log("Password reset successfully");
        setIsOpen(true);
        setType("success");
        setMessage("Password reset successfully");

        navigate("/login");
      }
      // handle success
    } catch (error) {
      setType("error");
      setMessage("Something went wrong");
      setIsOpen(true);
      console.error(error);
      // handle error
    }
  };
  return (
    <Div>
      <center>
        <div className="reset-password-container">
          <p className="reset-password-title">Reset Password</p>
          <form onSubmit={handleSubmit}>
            <div className="input-container">
              <label htmlFor="otp">Enter OTP</label> <br />
              <input
                type="text"
                id="otp"
                onChange={handleChange}
                value={formData.otp}
              />
            </div>
            <div className="input-container">
              <label htmlFor="password">New Password</label> <br />
              <input
                type="password"
                id="password"
                onChange={handleChange}
                value={formData.password}
              />
            </div>

            <div className="input-container">
              <label htmlFor="confirmPassword">Confirm Password</label> <br />
              <input
                type="password"
                id="confirmPassword"
                onChange={handleChange}
                value={formData.confirmPassword}
              />
            </div>

            <button type="submit" className="reset-password-button">
              Submit
            </button>
          </form>

          {isOpen && (
            <Toast
              type={type}
              message={message}
              isOpen={isOpen}
              setIsOpen={setIsOpen}
            />
          )}
        </div>
      </center>
    </Div>
  );
};

export default ResetPassword;
const Div = styled.div`
  justify-content: center;
  height: 400px;
  box-shadow:
    0px 106px 42px rgba(0, 0, 0, 0.01),
    0px 59px 36px rgba(0, 0, 0, 0.05),
    0px 26px 26px rgba(0, 0, 0, 0.09),
    0px 7px 15px rgba(0, 0, 0, 0.1),
    0px 0px 0px rgba(0, 0, 0, 0.1);
  border-radius: 11px;
  width: 400px;
  height: 600px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;

  .reset-password-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    margin: 0 auto;
    text-align: center;
  }

  .reset-password-title {
    color: #000;
    text-align: center;
    font-family: "Josefin sans", sans-serif;
    font-size: 21px;
    font-weight: 700;
    line-height: normal;
    margin: 10px 0 20px 0;
  }

  .input-container {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    width: 95%;
  }

  .input-container label {
    color: #000;
    font-family: "Josefin sans", sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: normal;
  }

  .input-container input {
    width: 100%;
    border-radius: 19px;
    border: 1px solid rgba(0, 0, 0, 0.5);
    background: #d9d9d9;
    padding: 20px;
    outline: none;
    color: #000;
    font-family: "Josefin sans", sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: normal;
  }

  .reset-password-button {
    width: 90%;
    padding: 10px 15px;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    background: #c7db00;
    outline: none;
    border: none;
    color: #000;
    text-align: center;
    font-family: "Josefin Sans", sans-serif;
    font-size: 25px;
    font-weight: 700;
    line-height: normal;
    margin-top: 20px;
    cursor: pointer;
  }
`;
