// import React from "react";
// import { Link } from "react-router-dom";
//
// const Listings = ({ items }) => {
//   return (
//     <ul className="list-none p-0 m-0 bg-white z-20">
//       {items.map((item, index) => (
//         <li key={index} className="p-4 border-b border-gray-300">
//           <Link to={`/details/${item.id}`} className="no-underline text-black">
//             <h2 className="font-bold">{item.name}</h2>
//             <p>{item.description}</p>
//           </Link>
//         </li>
//       ))}
//     </ul>
//   );
// };
//
// export default Listings;

import React, { useState } from "react";
import { Link } from "react-router-dom";

const Listings = ({ items, search, selected }) => {
  const [isClicked, setIsClicked] = useState(false); // New state variable

  const handleClick = () => {
    setIsClicked(true); // Update isClicked to true when the list is clicked
  };

  if (items.length === 0 && (search || selected)) {
    return <p>Search not found</p>;
  }

  return (
    !isClicked && (
      <ul
        className="list-none p-0 m-0 bg-white z-20 absolute"
        onClick={handleClick}
      >
        {items.map((item, index) => (
          <li key={index} className="p-4 border-b border-gray-300">
            <Link
              to={`/details/${item.id}`}
              className="no-underline text-black"
            >
              <h2 className="font-bold">{item.name}</h2>
              <p>{item.description}</p>
            </Link>
          </li>
        ))}
      </ul>
    )
  );
};

export default Listings;
