// import React, { useEffect, useState } from "react";
// import { TiTick } from "react-icons/ti";
//
// const AnimatedModalButton = ({ label = "", modalOpen = true, message }) => {
//   const [isVisible, setIsVisible] = useState(true);
//
//   useEffect(() => {
//     const timer = setTimeout(() => {
//       setIsVisible(false);
//     }, 5000);
//
//     return () => clearTimeout(timer);
//   }, []);
//
//   useEffect(() => {
//     if (modalOpen) {
//       document.getElementById("popup-modal")?.classList.remove("hidden");
//     } else {
//       document.getElementById("popup-modal")?.classList.add("hidden");
//     }
//   }, [modalOpen]);
//
//   if (!isVisible) {
//     return null;
//   }
//   return (
//     <>
//       <button
//         className="block text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 animate-bounce"
//         type="button"
//       >
//         {label}
//       </button>
//
//       <div
//         id="popup-modal"
//         tabIndex="-1"
//         className={`fixed inset-0 flex items-center justify-center z-50 overflow-y-auto overflow-x-hidden ${
//           modalOpen ? "" : "hidden"
//         }`}
//         style={{ backgroundColor: "rgba(0,0,0,0.5)" }} // This adds the backdrop
//       >
//         <div className="relative p-4 w-full max-w-md max-h-full">
//           <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
//             <div className="p-4 md:p-5 text-center">
//               <TiTick className="mx-auto w-12 h-12 text-green-900" />
//               <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
//                 {message}
//               </h3>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };
//
// export default AnimatedModalButton;

import React, { useEffect, useState } from "react";
import { TiTick } from "react-icons/ti";
import { MdError } from "react-icons/md"; // Import error icon

const AnimatedModalButton = ({
  label = "",
  modalOpen = true,
  successMessage,
  errorMessage,
  closeModal,
}) => {
  const [isVisible, setIsVisible] = useState(true);
  const [message, setMessage] = useState(""); // Add a state to handle the message
  const [isError, setIsError] = useState(false); // Add a state to handle the error

  const handleClose = () => {
    closeModal();
  };
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(false);
      handleClose();
    }, 5000);

    return () => clearTimeout(timer);
  }, [handleClose]);

  useEffect(() => {
    if (modalOpen) {
      document.getElementById("popup-modal")?.classList.remove("hidden");
    } else {
      document.getElementById("popup-modal")?.classList.add("hidden");
    }
  }, [modalOpen]);

  useEffect(() => {
    if (errorMessage) {
      setMessage(errorMessage);
      setIsError(true);
    } else if (successMessage) {
      setMessage(successMessage);
      setIsError(false);
    }
  }, [successMessage, errorMessage]);

  if (!isVisible) {
    return null;
  }

  return (
    <>
      {/*<button*/}
      {/*  className="block text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 animate-bounce"*/}
      {/*  type="button"*/}
      {/*>*/}
      {/*  {label}*/}
      {/*</button>*/}
      <div
        id="popup-modal"
        tabIndex="-1"
        className={`fixed inset-0 flex items-center justify-center z-50 overflow-y-auto overflow-x-hidden ${
          modalOpen ? "" : "hidden"
        }`}
        style={{ backgroundColor: "rgba(0,0,0,0.5)" }} // This adds the backdrop
      >
        <div className="relative p-4 w-full max-w-md max-h-full">
          <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
            <div className="p-4 md:p-5 text-center">
              {isError ? (
                <MdError className="mx-auto w-12 h-12 text-red-600" />
              ) : (
                <TiTick className="mx-auto w-12 h-12 text-green-900" />
              )}
              <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                {message}
              </h3>
              <button
                className="block text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 animate-bounce"
                type="button"
              >
                {label}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AnimatedModalButton;
