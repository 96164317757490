// // import React from "react";
// import Navbar from "../components/nav-component/Navbar";
// import Styled from "styled-components";
//
// import CardSlider from "../components/Sliders/HotelSlider";
// import Card from "../components/Card-components/Card";
// import SearchCard from "../components/Card-components/SearchCard";
// import Footer from "../components/Footer";
// import { Link } from "react-router-dom";
// import LikeButton from "../components/LikeButton";
//
// const HotelHompage = () => {
//   //this is the data that's in the review card currently
//
//   const cardsData = [
//     {
//       name: "magret Mondeh",
//       review:
//         "Lorem ipsum dolor sit amet consectetur. Sodales sagittis dictumst at lorem .",
//       image: "assets/Hotel-card1.png",
//       title: "Chicken Town",
//     },
//
//     {
//       name: "Pope Kamara",
//       review:
//         "Lorem ipsum dolor sit amet consectetur. Sodales sagittis dictumst at lorem .",
//       image: "assets/Hotel-card2.png",
//       title: "Basha",
//     },
//
//     {
//       name: "Samuel Kamara",
//       review:
//         "Lorem ipsum dolor sit amet consectetur. Sodales sagittis dictumst at lorem .",
//       image: "assets/Hotel-card3.png",
//       title: "Basha",
//     },
//     {
//       name: "Maggie Kamara",
//       review:
//         "Lorem ipsum dolor sit amet consectetur. Sodales sagittis dictumst at lorem .",
//       image: "assets/Hotel-card4.png",
//       title: "Basha",
//     },
//     {
//       name: "Maggie Kamara",
//       review:
//         "Lorem ipsum dolor sit amet consectetur. Sodales sagittis dictumst at lorem .",
//       image: "assets/Hotel-card5.png",
//       title: "Basha",
//     },
//     {
//       name: "Maggie Kamara",
//       review:
//         "Lorem ipsum dolor sit amet consectetur. Sodales sagittis dictumst at lorem .",
//       image: "assets/Hotel-card6.png",
//       title: "Basha",
//     },
//     {
//       name: "Maggie Kamara",
//       review:
//         "Lorem ipsum dolor sit amet consectetur. Sodales sagittis dictumst at lorem .",
//       image: "assets/Hotel-card3.png",
//       title: "Basha",
//     },
//
//     {
//       name: "Maggie Kamara",
//       review:
//         "Lorem ipsum dolor sit amet consectetur. Sodales sagittis dictumst at lorem .",
//       image: "assets/card2.png",
//       title: "Basha",
//     },
//     {
//       name: "Maggie Kamara",
//       review:
//         "Lorem ipsum dolor sit amet consectetur. Sodales sagittis dictumst at lorem .",
//       image: "assets/Hotel-card3.png",
//       title: "Basha",
//     },
//   ];
//
//   //this is the data for the more option card
//
//   const cardDataArray = [
//     {
//       imageSrc: "assets/Hotelrelate1.png",
//       title: "Basha Bakery & Restaurant",
//       address: "73 Wilkinson Road",
//       phone: "Phone (+232 76611000)",
//
//       description:
//         "um sit a. Sit ut dolor iaculis volutpat montes mauris nunc volutpat fermentum.",
//     },
//     {
//       imageSrc: "assets/Hotelrelate2.png",
//       title: "Pa Sweet Bakery",
//       address: "Basha Bakery & Resturant",
//       phone: "Phone (+232 76611000)",
//
//       description:
//         "um sit a. Sit ut dolor iaculis volutpat montes mauris nunc volutpat fermentum.",
//     },
//     {
//       imageSrc: "assets/Hotelrelate3.png",
//       title: "City Sandwich",
//       address: "70 Wilkinson Road",
//       phone: "Phone (+232 76611000)",
//
//       description:
//         "um sit a. Sit ut dolor iaculis volutpat montes mauris nunc volutpat fermentum.",
//     },
//
//     {
//       imageSrc: "assets/Hotelrelate4.png",
//       title: "Basha Bakery & Restaurant",
//       address: "73 Wilkinson Road",
//       phone: "Phone (+232 76611000)",
//
//       description:
//         "um sit a. Sit ut dolor iaculis volutpat montes mauris nunc volutpat fermentum.",
//     },
//     {
//       imageSrc: "assets/Hotelrelate3.png",
//       title: "Pa Sweet Bakery",
//       address: "Basha Bakery & Resturant",
//       phone: "Phone (+232 76611000)",
//
//       description:
//         "um sit a. Sit ut dolor iaculis volutpat montes mauris nunc volutpat fermentum.",
//     },
//     {
//       imageSrc: "assets/Hotelrelate2",
//       title: "City Sandwich",
//       address: "70 Wilkinson Road",
//       phone: "Phone (+232 76611000)",
//
//       description:
//         "um sit a. Sit ut dolor iaculis volutpat montes mauris nunc volutpat fermentum.",
//     },
//   ];
//
//   return (
//     <Div>
//       {/* this is the heading of the page */}
//
//       <div className="header">
//         <Navbar />
//       </div>
//       {/* <div className="search">
//         <Search />
//       </div> */}
//       {/* the  heading section ends here */}
//
//       {/* main content of the page starts here */}
//
//       <main>
//         {/* the section  for the logo and the stars  ends here*/}
//
//         <div className="head">
//           <div className="head-1">
//             <img src="assets/chicken.png" alt="" />
//             <h1>Chicken Town(Lumley)</h1>
//           </div>
//           <div className="head-2">
//             <img src="assets/star.png" alt="" />
//             <img src="assets/star.png" alt="" />
//             <img src="assets/star.png" alt="" />
//             <img src="assets/star.png" alt="" />
//             <img src="assets/star.png" alt="" />
//           </div>
//         </div>
//         {/* the section  for the logo and the stars  ends here*/}
//
//         {/*
//         this is the here slider section */}
//         <div className="slider">
//           <CardSlider />
//         </div>
//
//         {/* this section is for the share, favourite and review  buttons */}
//
//         <div className="share">
//           <button>
//             <span>Share </span>
//             <img src="assets/share.png" alt="" />
//           </button>
//           <LikeButton />
//
//           <Link to="/review" style={{ textDecoration: "none" }}>
//             <button>
//               <span> Write a Review</span>
//               <img src="assets/star.png" alt="" />
//             </button>
//           </Link>
//         </div>
//         {/* the section  for the share, favourite and review  buttons ends here */}
//
//         {/* this section is for the menu and direction */}
//
//         <div className="direc--photo">
//           <div className="photos">
//             <h1 className="menu--head">Photos</h1>
//
//             <div className="photoss">
//               <div className="photo">
//                 <img src="assets/Hotel5.png" alt="" />
//                 <h3>Pool</h3>
//               </div>
//               <div className="photo">
//                 <img src="assets/Hotel6.png" alt="" />
//                 <h3>Wine Bar</h3>
//               </div>
//               <div className="photo">
//                 <img src="assets/Hotel7.png" alt="" />
//                 <h3>Fine Dinning</h3>
//               </div>
//               <div className="photo">
//                 <img src="assets/Hotel8.png" alt="" />
//                 <h3>Gym</h3>
//               </div>
//             </div>
//           </div>
//
//           <div className="direction">
//             <button>
//               <img src="assets/redlocation.png" alt="" />
//               <span>73 Wilkinson Road</span>
//             </button>
//             <button>
//               <img src="assets/direction.png" alt="" />
//               <span>Get Direction</span>
//             </button>
//             <button>
//               <img src="assets/phone1.png" alt="" />
//               <span>Phone (+232) 76772277</span>
//             </button>
//             <button>
//               <img src="assets/website.png" alt="" />
//               <span>Go to website</span>
//             </button>
//             <button>
//               <img src="assets/media.png" alt="" />
//               <span>Social media link</span>
//             </button>
//           </div>
//         </div>
//         <br />
//         {/* menu and direction section ends here */}
//
//         {/* description section starts here */}
//
//         <div className="description">
//           <h1>Description</h1>
//
//           <div className="desc">
//             <div className="desc1">
//               <h3>Price range</h3>
//               <p>SLL 200/ SLL 500</p>
//             </div>
//
//             <div className="desc2">
//               <h3>Cuisine</h3>
//               <p>
//                 Lebanese, Africana, Italian, Traditional Vegetarian, Vegan Meals
//               </p>
//             </div>
//
//             <div className="desc2">
//               <h3>Features</h3>
//               <p>Bar, Dinning, Bakery, free wifi, Reservations</p>
//             </div>
//           </div>
//         </div>
//       </main>
//       {/* description section ends here */}
//
//       {/* the review card section starts here */}
//
//       <section className="carrd">
//         {cardsData.map((data, index) => (
//           <Card
//             key={index}
//             name={data.name}
//             title={data.title}
//             review={data.review}
//             image={data.image}
//           />
//         ))}
//       </section>
//       {/* the review card section ends here */}
//
//       {/*
//     more option cards starts here */}
//
//       <div className="last-sec">
//         <h1>More Option for you</h1>
//         <div className="cardss">
//           {cardDataArray.map((data, index) => (
//             <SearchCard
//               key={index}
//               imageSrc={data.imageSrc}
//               title={data.title}
//               address={data.address}
//               phone={data.phone}
//               description={data.description}
//             />
//           ))}
//         </div>
//       </div>
//       {/* more option card ends here */}
//
//       <div className="footer">
//         <Footer />
//       </div>
//     </Div>
//   );
// };
//
// export default HotelHompage;
//
// const Div = Styled.div`
// overflow:hidden;
//
// .header{
//   margin-top:5px;
// }
//
//
//   .login {
//     color: black;
//   }
//   .btn {
//     color: black;
//   }
//
//   .drop {
//     display: flex;
//     padding: 10px;
//     align-items: flex-start;
//     gap: 10px;
//     border-radius: 10px;
//     border: 1px solid #d9d9d9;
//     background: rgba(255, 255, 255, 0);
//     margin-top: 0px;
//   }
//
// .header{
//     margin-bottom:70px;
// }
//
// .head{
//   display:flex;
//   justify-content:space-between;
//    align-items:center;
//    margin-top:30px;
//    margin-bottom:10px;
// }
//
// .head-1{
//   display:flex;
//     gap:30px;
//     align-items:center;
//     margin-left: 15px;
// }
//
// .head-1 img{
// max-width:98px;
// max-height:95px;
// }
//
// .head-1 h1{
//    color: #000000;
//   text-align: left;
//   font-size:40px;
//   font-weight: 700;
//   font-family:"Josefin Sans", sans-serif;
//   margin:0;
// }
//
// .head-2{
//   display:flex;
//   flex-wrap:wrap;
//   justify-content:center;
//   gap:5px;
//   margin-right:10px;
// }
//
//
//
// .share{
//   justify-content:center;
//   display:flex;
//   margin-bottom:20px;
//   gap:8px;
// }
//
// .share button{
//   height:45px;
//   border-right: 4px solid  red;
//   border-left:none;
//   border-top:none;
//   border-bottom:none;
//   background: white;
//    color: #000000;
//   text-align: center;
//   font: 500 21px "Josefin Sans", sans-serif;
//   display:flex;
//   align-items:center;
//   padding:10px;
//     transition: .2s linear;
//     gap:5px;
// }
// button:hover > img {
//   transform: scale(1.2);
// }
//
//
// .photos{
//   display:flex;
//   flex-direction:column;
//   gap:20px;
//   margin-left:20px;
//   flex-wrap:wrap;
//
// }
//
// .photoss{
//   display:flex;
//   gap:20px;
// }
//
//
//
// .photo{
//   display:flex;
//   flex-direction: column;
//   gap:13px;
//   border: 1px solid rgba(0, 0, 0, 0.25);
//   max-width:198px;
//
// }
//
// .photo h3{
//   color: #000000;
//   text-align: left;
//   font: 400 21px "Josefin Sans", sans-serif;
//   margin-top:10px;
//   margin-bottom:10px;
// }
//
// .menu--head{
//   color: #000000;
//   font: 700 21px "Josefin sans", sans-serif;
//   margin-bottom:0px;
//   margin-top:0px;
//   text-align:left;w
// }
//
// .direc--photo{
//   display:flex;
//   justify-content: space-between;
//   margin-bottom:20px;
//   margin-left:20px;
//   margin-right:20px;
//
//
// }
//
//
// .direction{
// display:flex;
// flex-direction:column;
// align-items:flex-start;
// background: #FFF;
// box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
// max-width: 327px;
// gap:10px;
// overflow:hidden;
// }
//
// .direction button{
//   display:flex;
//   align-items:center;
//   gap:10px;
//   border:none;
// border-bottom: 1px solid rgba(0, 0, 0, 0.25);
// background: #FFF;
// padding:10px;
// width:100%;
//
//
// color: rgba(250, 11, 11, 0.99);
// font-family: 'Josefin Sans', sans-serif;
// font-size: 21px;
// font-style: normal;
// font-weight: 400;
// line-height: normal;
// cursor:pointer;
//
// }
// .direction button:hover{
//   background:rgba(0, 0, 0, 0.25);
//
// }
//
//
// .direction button img{
//   width:24px;
//   height:24px;
// }
//
// .desc{
//   display:flex;
//   margin-bottom: 150px;
//   gap:30px;
// }
//
// .desc1{
// display:flex;
// flex-direction:column;
// max-width:243px;
// border-radius: 10px;
// background: #FFF;
// box-shadow: 0px 4px 4px 0px #C7CDFE;
// gap:10px;
// padding:10px;
// height:89px;
// }
//
// .desc2{
//   display:flex;
//   max-width:333px;
//   flex-direction:column;
//   gap:15px;
//   border-radius: 10px;
// background: #FFF;
// box-shadow: 0px 4px 4px 0px #C7CDFE;
// padding:10px;
// height:108px;
//
// }
//
//
//
//
// .description h3{
// color: black;
// font-family: "Josefin sans", sans-serif;
// font-size: 21px;
// font-style: normal;
// font-weight: 700;
// line-height: normal;
//
// }
//
//
// .description h1{
//   color: black;
// font-family: "Josefin sans", sans-serif;
// font-size: 21px;
// font-style: normal;
// font-weight: 700;
// line-height: normal;
// margin:0px;
// text-align:left;
// }
//
//
//
//
// .description p{
//   color: #000;
// font-family: "Josefin sans", sans-serif;
// font-size: 16px;
// font-style: normal;
// font-weight: 300;
// line-height: normal;
// }
//
// .description{
// margin-left:30px;
// display:flex;
// flex-direction:column;
// gap:20px;
// align-items:flex-start;
//
// }
//
//
//
//
//
//
//   .last-sec{
//     display:flex;
//     flex-direction:column;
//   }
//
//
//   .last-sec h1{
//     color: #000;
// text-align: center;
// font-family: "Josefin sans", sans-serif;
// font-size: 21px;
// font-style: normal;
// font-weight: 600;
// line-height: normal;
// margin-top:50px;
// margin-bottom:50px;
//
//   }
//
//
//   .carrd {
//     display: grid;
//     grid-template-columns: repeat(3, 400px);
//     justify-items: center;
//     justify-content:center;
//     gap:20px;
//     margin: 0 auto;
//
//   }
//
//   .card--1{
//     margin:0;
//
//   }
//
//
//
//
//   .cardss{
//     display:flex;
//     justify-content:space-between;
//     flex-wrap:wrap;
//     margin-left:10px;
//     margin-right:10px;
//     gap:20px;
//   }
//
//
//
// .cardsss{
//   max-width:580px;
//   flex-basis: calc(50% - 20px);
//
//
// }
//
//
//
//
//
//
//
//
//
//
//   @media (max-width: 1024px) {
//     .cards {
//       grid-template-columns: repeat(2, 1fr);
//       justify-content: center; /* Adjust spacing for laptop screens */
//     }
//   }
//
//
//
//
//
//
//   @media (max-width: 768px) {
//     overflow:hidden;
//     .cards {
//       grid-template-columns: repeat(1, 1fr);
//       justify-content: center; /* Adjust for smaller screens */
//     }
//
//
//     .head-1{
//   display:flex;
//     gap:10px;
//     align-items:center;
//     margin-left:1px;
//     justify-content:center;
// }
//
// .head{
//   align-items:center;
// }
//
// .head-1 img{
//   width:50px;
//   height:50px;
//
// }
//
// .head-1 h1{
//      font-size:20px;
//  }
//
// .head-2 {
//
// }
//
// .share button{
//   font-size:15px;
// }
//
// .photoss{
//   display:flex;
//   gap:50px;
//   flex-direction:column;
//   justify-content:center;
//    margin-left:50px;
//    text-align:center;
//    margin-bottom:20px;
// }
// .direction{
//   justify-content:center;
//   margin:0 auto;
// }
//
//
// .direc--photo{
//   display:flex;
//   flex-direction:column;
//   justify-content: space-between;
//   margin-bottom:20px;
// }
//
// .desc{
//   display:flex;
//   flex-wrap:wrap;
//   margin-left:2px;
// }
//
// .cardss{
//   display:flex;
//   flex-direction:column;
//   gap:20px;
// }
//
// .main-drop{
//   display: none;
//
// }
//
// .icon{
//   color:black;
// }
//
// .search{
//   border:1px solid black;
// }
//
// .myimage{
//   max-width:100%;
//   height:200px;
//   object-fit:cover;
//   position:center;
//
// }
//
//
//
//   }
// `;

// // import React from "react";
// import Navbar from "../components/nav-component/Navbar";
// import Styled from "styled-components";
//
// import CardSlider from "../components/Sliders/PharmarcySlider";
// import Card from "../components/Card-components/Card";
// import SearchCard from "../components/Card-components/SearchCard";
// import Footer from "../components/Footer";
// import { Link } from "react-router-dom";
// import LikeButton from "../components/LikeButton";
//
// const Pharmarcy = () => {
//   //this is the data that's in the review card currently
//
//   const cardsData = [
//     {
//       name: "magret Mondeh",
//       review:
//         "Lorem ipsum dolor sit amet consectetur. Sodales sagittis dictumst at lorem .",
//       image: "assets/pharm9.png",
//       title: "Chicken Town",
//     },
//
//     {
//       name: "Pope Kamara",
//       review:
//         "Lorem ipsum dolor sit amet consectetur. Sodales sagittis dictumst at lorem .",
//       image: "assets/pharm10.png",
//       title: "Basha",
//     },
//
//     {
//       name: "Samuel Kamara",
//       review:
//         "Lorem ipsum dolor sit amet consectetur. Sodales sagittis dictumst at lorem .",
//       image: "assets/pharm11.png",
//       title: "Basha",
//     },
//     {
//       name: "Maggie Kamara",
//       review:
//         "Lorem ipsum dolor sit amet consectetur. Sodales sagittis dictumst at lorem .",
//       image: "assets/pharm.png",
//       title: "Basha",
//     },
//     {
//       name: "Maggie Kamara",
//       review:
//         "Lorem ipsum dolor sit amet consectetur. Sodales sagittis dictumst at lorem .",
//       image: "assets/Hotel-card5.png",
//       title: "Basha",
//     },
//     {
//       name: "Maggie Kamara",
//       review:
//         "Lorem ipsum dolor sit amet consectetur. Sodales sagittis dictumst at lorem .",
//       image: "assets/Hotel-card6.png",
//       title: "Basha",
//     },
//     {
//       name: "Maggie Kamara",
//       review:
//         "Lorem ipsum dolor sit amet consectetur. Sodales sagittis dictumst at lorem .",
//       image: "assets/Hotel-card3.png",
//       title: "Basha",
//     },
//
//     {
//       name: "Maggie Kamara",
//       review:
//         "Lorem ipsum dolor sit amet consectetur. Sodales sagittis dictumst at lorem .",
//       image: "assets/card2.png",
//       title: "Basha",
//     },
//     {
//       name: "Maggie Kamara",
//       review:
//         "Lorem ipsum dolor sit amet consectetur. Sodales sagittis dictumst at lorem .",
//       image: "assets/Hotel-card3.png",
//       title: "Basha",
//     },
//   ];
//
//   //this is the data for the more option card
//
//   const cardDataArray = [
//     {
//       imageSrc: "assets/pharm12.png",
//       title: "Basha Bakery & Restaurant",
//       address: "73 Wilkinson Road",
//       phone: "Phone (+232 76611000)",
//
//       description:
//         "um sit a. Sit ut dolor iaculis volutpat montes mauris nunc volutpat fermentum.",
//     },
//     {
//       imageSrc: "assets/pharm13.png",
//       title: "Pa Sweet Bakery",
//       address: "Basha Bakery & Resturant",
//       phone: "Phone (+232 76611000)",
//
//       description:
//         "um sit a. Sit ut dolor iaculis volutpat montes mauris nunc volutpat fermentum.",
//     },
//     {
//       imageSrc: "assets/Hotelrelate3.png",
//       title: "City Sandwich",
//       address: "70 Wilkinson Road",
//       phone: "Phone (+232 76611000)",
//
//       description:
//         "um sit a. Sit ut dolor iaculis volutpat montes mauris nunc volutpat fermentum.",
//     },
//
//     {
//       imageSrc: "assets/Hotelrelate4.png",
//       title: "Basha Bakery & Restaurant",
//       address: "73 Wilkinson Road",
//       phone: "Phone (+232 76611000)",
//
//       description:
//         "um sit a. Sit ut dolor iaculis volutpat montes mauris nunc volutpat fermentum.",
//     },
//     {
//       imageSrc: "assets/Hotelrelate3.png",
//       title: "Pa Sweet Bakery",
//       address: "Basha Bakery & Resturant",
//       phone: "Phone (+232 76611000)",
//
//       description:
//         "um sit a. Sit ut dolor iaculis volutpat montes mauris nunc volutpat fermentum.",
//     },
//     {
//       imageSrc: "assets/Hotelrelate2",
//       title: "City Sandwich",
//       address: "70 Wilkinson Road",
//       phone: "Phone (+232 76611000)",
//
//       description:
//         "um sit a. Sit ut dolor iaculis volutpat montes mauris nunc volutpat fermentum.",
//     },
//   ];
//
//   return (
//     <Div>
//       {/* this is the heading of the page */}
//       <div className="header">
//         <Navbar />
//       </div>
//       {/* <div className="search">
//         <Search />
//       </div> */}
//       {/* the  heading section ends here */}
//
//       {/* main content of the page starts here */}
//
//       <main>
//         {/* the section  for the logo and the stars  ends here*/}
//
//         <div className="head">
//           <div className="head-1">
//             <img src="assets/pharm.png" alt="" />
//             <h1>Cotton Tree Pharmarcy</h1>
//           </div>
//           <div className="head-2">
//             <img src="assets/star.png" alt="" />
//             <img src="assets/star.png" alt="" />
//             <img src="assets/star.png" alt="" />
//             <img src="assets/star.png" alt="" />
//             <img src="assets/star.png" alt="" />
//           </div>
//         </div>
//         {/* the section  for the logo and the stars  ends here*/}
//
//         {/*
//         this is the here slider section */}
//         <div className="slider">
//           <CardSlider />
//         </div>
//
//         {/* this section is for the share, favourite and review  buttons */}
//
//         <div className="share">
//           <button>
//             <span>Share </span>
//             <img src="assets/share.png" alt="" />
//           </button>
//           <LikeButton />
//
//           <Link to="/review" style={{ textDecoration: "none" }}>
//             <button>
//               <span> Write a Review</span>
//               <img src="assets/star.png" alt="" />
//             </button>
//           </Link>
//         </div>
//         {/* the section  for the share, favourite and review  buttons ends here */}
//
//         {/* this section is for the menu and direction */}
//
//         <div className="direc--photo">
//           <div className="photos">
//             <h1 className="menu--head">Photos</h1>
//
//             <div className="photoss">
//               <div className="photo">
//                 <img src="assets/pharm5.png" alt="" />
//                 <h3>Medicine</h3>
//               </div>
//               <div className="photo">
//                 <img src="assets/pharm6.png" alt="" />
//                 <h3>Cosmetics</h3>
//               </div>
//               <div className="photo">
//                 <img src="assets/pharm7.png" alt="" />
//                 <h3>Mini Mart</h3>
//               </div>
//               <div className="photo">
//                 <img src="assets/pharm8.png" alt="" />
//                 <h3>Baby Product</h3>
//               </div>
//             </div>
//           </div>
//
//           <div className="direction">
//             <button>
//               <img src="assets/redlocation.png" alt="" />
//               <span>73 Wilkinson Road</span>
//             </button>
//             <button>
//               <img src="assets/direction.png" alt="" />
//               <span>Get Direction</span>
//             </button>
//             <button>
//               <img src="assets/phone1.png" alt="" />
//               <span>Phone (+232) 76772277</span>
//             </button>
//             <button>
//               <img src="assets/website.png" alt="" />
//               <span>Go to website</span>
//             </button>
//             <button>
//               <img src="assets/media.png" alt="" />
//               <span>Social media link</span>
//             </button>
//           </div>
//         </div>
//         <br />
//         {/* menu and direction section ends here */}
//
//         {/* description section starts here */}
//
//         <div className="description">
//           <h1>Description</h1>
//
//           <div className="desc">
//             <div className="desc1">
//               <h3>Price range</h3>
//               <p>SLL 200/ SLL 500</p>
//             </div>
//
//             <div className="desc2">
//               <h3>Features</h3>
//               <p>Bar, Dinning, Bakery, free wifi, Reservations</p>
//             </div>
//           </div>
//         </div>
//       </main>
//       {/* description section ends here */}
//
//       {/* the review card section starts here */}
//
//       <section className="carrd">
//         {cardsData.map((data, index) => (
//           <Card
//             key={index}
//             name={data.name}
//             title={data.title}
//             review={data.review}
//             image={data.image}
//           />
//         ))}
//       </section>
//       {/* the review card section ends here */}
//
//       {/*
//     more option cards starts here */}
//
//       <div className="last-sec">
//         <h1>More Option for you</h1>
//         <div className="cardss">
//           {cardDataArray.map((data, index) => (
//             <SearchCard
//               key={index}
//               imageSrc={data.imageSrc}
//               title={data.title}
//               address={data.address}
//               phone={data.phone}
//               description={data.description}
//             />
//           ))}
//         </div>
//       </div>
//
//       {/* more option card ends here */}
//
//       <div className="footer">
//         <Footer />
//       </div>
//     </Div>
//   );
// };
//
// export default Pharmarcy;
//
// const Div = Styled.div`
// overflow:hidden;
//
// .header{
//   margin-top:5px;
// }
//
//
//   .login {
//     color: black;
//   }
//   .btn {
//     color: black;
//   }
//
//   .drop {
//     display: flex;
//     padding: 10px;
//     align-items: flex-start;
//     gap: 10px;
//     border-radius: 10px;
//     border: 1px solid #d9d9d9;
//     background: rgba(255, 255, 255, 0);
//     margin-top: 0px;
//   }
//
// .header{
//     margin-bottom:70px;
// }
//
//
// .head{
//   display:flex;
//   justify-content:space-between;
//    align-items:center;
//    margin-top:30px;
//    margin-bottom:10px;
// }
//
// .head-1{
//   display:flex;
//     gap:30px;
//     align-items:center;
//     margin-left: 15px;
//
// }
//
// .head-1 img{
// max-width:98px;
// max-height:95px;
// }
//
// .head-1 h1{
//    color: #000000;
//   text-align: left;
//   font-size:40px;
//   font-weight: 700;
//   font-family:"Josefin Sans", sans-serif;
//   margin:0;
// }
//
// .head-2{
//   display:flex;
//   flex-wrap:wrap;
//   justify-content:center;
//   gap:5px;
//   margin-right:10px;
// }
//
//
// .share{
//   justify-content:center;
//   display:flex;
//   margin-bottom:20px;
//   gap:8px;
// }
//
// .share button{
//   height:45px;
//   border-right: 4px solid  red;
//   border-left:none;
//   border-top:none;
//   border-bottom:none;
//   background: white;
//    color: #000000;
//   text-align: center;
//   font: 500 21px "Josefin Sans", sans-serif;
//   display:flex;
//   align-items:center;
//   gap:5px;
//   padding:10px;
//   transition: .2s linear;
// }
// button:hover > img {
//   transform: scale(1.2);
// }
// .photos{
//   display:flex;
//   flex-direction:column;
//   gap:20px;
//   margin-left:20px;
//   flex-wrap:wrap;
//
// }
//
// .photoss{
//   display:flex;
//   gap:20px;
// }
//
//
//
// .photo{
//   display:flex;
//   flex-direction: column;
//   gap:13px;
//   border: 1px solid rgba(0, 0, 0, 0.25);
//   max-width:198px;
//
// }
//
// .photo h3{
//   color: #000000;
//   text-align: left;
//   font: 400 21px "Josefin Sans", sans-serif;
//   margin-top:10px;
//   margin-bottom:10px;
// }
//
// .menu--head{
//   color: #000000;
//   font: 700 21px "Josefin sans", sans-serif;
//   margin-bottom:0px;
//   margin-top:0px;
//   text-align:left;w
// }
//
// .direc--photo{
//   display:flex;
//   justify-content: space-between;
//   margin-bottom:20px;
//   margin-left:20px;
//   margin-right:20px;
//
//
// }
//
//
// .direction{
// display:flex;
// flex-direction:column;
// align-items:flex-start;
// background: #FFF;
// box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
// max-width: 327px;
// gap:10px;
// overflow:hidden;
// }
//
// .direction button{
//   display:flex;
//   align-items:center;
//   gap:10px;
//   border:none;
// border-bottom: 1px solid rgba(0, 0, 0, 0.25);
// background: #FFF;
// padding:10px;
// width:100%;
//
//
// color: rgba(250, 11, 11, 0.99);
// font-family: 'Josefin Sans', sans-serif;
// font-size: 21px;
// font-style: normal;
// font-weight: 400;
// line-height: normal;
// cursor:pointer;
//
// }
// .direction button:hover{
//   background:rgba(0, 0, 0, 0.25);
//
// }
//
//
// .direction button img{
//   width:24px;
//   height:24px;
// }
//
// .desc{
//   display:flex;
//   margin-bottom: 150px;
//   gap:30px;
// }
//
// .desc1{
// display:flex;
// flex-direction:column;
// max-width:243px;
// border-radius: 10px;
// background: #FFF;
// box-shadow: 0px 4px 4px 0px #C7CDFE;
// gap:10px;
// padding:10px;
// height:89px;
// }
//
// .desc2{
//   display:flex;
//   max-width:333px;
//   flex-direction:column;
//   gap:15px;
//   border-radius: 10px;
// background: #FFF;
// box-shadow: 0px 4px 4px 0px #C7CDFE;
// padding:10px;
// height:108px;
//
// }
//
//
//
//
// .description h3{
// color: black;
// font-family: "Josefin sans", sans-serif;
// font-size: 21px;
// font-style: normal;
// font-weight: 700;
// line-height: normal;
//
// }
//
//
// .description h1{
//   color: black;
// font-family: "Josefin sans", sans-serif;
// font-size: 21px;
// font-style: normal;
// font-weight: 700;
// line-height: normal;
// margin:0px;
// text-align:left;
// }
//
//
//
//
// .description p{
//   color: #000;
// font-family: "Josefin sans", sans-serif;
// font-size: 16px;
// font-style: normal;
// font-weight: 300;
// line-height: normal;
// }
//
// .description{
// margin-left:30px;
// display:flex;
// flex-direction:column;
// gap:20px;
// align-items:flex-start;
//
// }
//
//
//
//
//
//
//   .last-sec{
//     display:flex;
//     flex-direction:column;
//   }
//
//
//   .last-sec h1{
//     color: #000;
// text-align: center;
// font-family: "Josefin sans", sans-serif;
// font-size: 21px;
// font-style: normal;
// font-weight: 600;
// line-height: normal;
// margin-top:50px;
// margin-bottom:50px;
//
//   }
//
//
//   .carrd {
//     display: grid;
//     grid-template-columns: repeat(3, 400px);
//     justify-items: center;
//     justify-content:center;
//     gap:20px;
//     margin: 0 auto;
//
//   }
//
//   .card--1{
//     margin:0;
//
//   }
//
//
//
//
//   .cardss{
//     display:flex;
//     justify-content:space-between;
//     flex-wrap:wrap;
//     margin-left:10px;
//     margin-right:10px;
//     gap:20px;
//   }
//
//
//
// .cardsss{
//   max-width:580px;
//   flex-basis: calc(50% - 20px);
//
//
// }
//
//
//
//
//
//
//
//
//
//
//   @media (max-width: 1024px) {
//     .cards {
//       grid-template-columns: repeat(2, 1fr);
//       justify-content: center; /* Adjust spacing for laptop screens */
//     }
//   }
//
//
//
//
//
//
//   @media (max-width: 768px) {
//     overflow:hidden;
//     .cards {
//       grid-template-columns: repeat(1, 1fr);
//       justify-content: center; /* Adjust for smaller screens */
//     }
//
//
//     .head-1{
//   display:flex;
//     gap:10px;
//     align-items:center;
//     margin-left:1px;
//     justify-content:center;
// }
//
// .head{
//   align-items:center;
// }
//
// .head-1 img{
//   width:50px;
//   height:50px;
//
// }
//
// .head-1 h1{
//      font-size:20px;
//  }
//
// .head-2 {
//
// }
//
// .share button{
//   font-size:15px;
// }
//
// .photoss{
//   display:flex;
//   gap:50px;
//   flex-direction:column;
//   justify-content:center;
//    margin-left:50px;
//    text-align:center;
//    margin-bottom:20px;
// }
// .direction{
//   justify-content:center;
//   margin:0 auto;
// }
//
//
// .direc--photo{
//   display:flex;
//   flex-direction:column;
//   justify-content: space-between;
//   margin-bottom:20px;
// }
//
// .desc{
//   display:flex;
//   flex-wrap:wrap;
//   margin-left:2px;
// }
//
// .cardss{
//   display:flex;
//   flex-direction:column;
//   gap:20px;
// }
//
// .main-drop{
//   display: none;
//
// }
//
// .icon{
//   color:black;
// }
//
// .search{
//   border:1px solid black;
// }
//
// .myimage{
//   max-width:100%;
//   height:200px;
//   object-fit:cover;
//   position:center;
//
// }
//
//
//
//   }
// `;

/* eslint-disable react/no-unescaped-entities */
import {useEffect, useState} from "react";
import styled from "styled-components";
// import Dropdown from "../components/Dropdown";
// import Buttons from "../components/nav-component/Buttons";
import Search from "../components/nav-component/Search";
import SearchCard from "../components/Card-components/SearchCard";
import Navbar from "../components/nav-component/Navbar";
import axios from "axios";
import {Link} from "react-router-dom";
import API_BASE_URL from "../auth/apiConfig";

const Find = () => {
  // this is  to select multiple buttons all as they get clicked.
  const [selectedButtons, setSelectedButtons] = useState([]);
  const [pharmacyData, setPharmacyData] = useState([]);

  const handleClick = (buttonName) => {
    if (selectedButtons.includes(buttonName)) {
      // If the button is already selected, remove it from the selectedButtons array
      setSelectedButtons((prevSelected) =>
        prevSelected.filter((name) => name !== buttonName),
      );
    } else {
      // If the button is not selected, add it to the selectedButtons array
      setSelectedButtons((prevSelected) => [...prevSelected, buttonName]);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/place`);
        const data = response.data;
        const filteredData = data.filter((item) => item.place_type === "Hotel");
        setPharmacyData(filteredData);
      } catch (error) {
        console.error("Error fetching data", error);
      }
    };

    fetchData();
  }, []);

  console.log(pharmacyData);
  //  this is dummy data for the cards in this page

  const cardDataArray = [
    {
      imageSrc: "assets/card-img.png",
      title: "Basha Bakery & Restaurant",
      address: "73 Wilkinson Road",
      phone: "Phone (+232 76611000)",

      description:
        "um sit a. Sit ut dolor iaculis volutpat montes mauris nunc volutpat fermentum.",
    },
    {
      imageSrc: "assets/card-11.png",
      title: "Pa Sweet Bakery",
      address: "Basha Bakery & Resturant",
      phone: "Phone (+232 76611000)",

      description:
        "um sit a. Sit ut dolor iaculis volutpat montes mauris nunc volutpat fermentum.",
    },
    {
      imageSrc: "assets/card-12.png",
      title: "City Sandwich",
      address: "70 Wilkinson Road",
      phone: "Phone (+232 76611000)",

      description:
        "um sit a. Sit ut dolor iaculis volutpat montes mauris nunc volutpat fermentum.",
    },

    {
      imageSrc: "assets/card-img.png",
      title: "Basha Bakery & Restaurant",
      address: "73 Wilkinson Road",
      phone: "Phone (+232 76611000)",

      description:
        "um sit a. Sit ut dolor iaculis volutpat montes mauris nunc volutpat fermentum.",
    },
    {
      imageSrc: "assets/card-11.png",
      title: "Pa Sweet Bakery",
      address: "Basha Bakery & Resturant",
      phone: "Phone (+232 76611000)",

      description:
        "um sit a. Sit ut dolor iaculis volutpat montes mauris nunc volutpat fermentum.",
    },
    {
      imageSrc: "assets/card-12.png",
      title: "City Sandwich",
      address: "70 Wilkinson Road",
      phone: "Phone (+232 76611000)",

      description:
        "um sit a. Sit ut dolor iaculis volutpat montes mauris nunc volutpat fermentum.",
    },
  ];

  return (
    <Div>
      <Navbar />

      <div className="search">
        <Search />
      </div>
      <main>
        {/* <div className="">
          <Search />
        </div> */}

        {/* this the main section of this page   */}

        {/* the checkbox at the side starts here */}
        <div className="fill">
          {/* first checkbox section */}
          <div className="line-up">
            {/*<h4 className="suggested">Suggested</h4>*/}
            {/*<form action="">*/}
            {/*  <div className="check">*/}
            {/*    <input type="checkbox" name="" id="checkbox" />*/}
            {/*    <label htmlFor="checkbox">Open now</label>*/}
            {/*  </div>*/}

            {/*  <div className="check">*/}
            {/*    <input type="checkbox" name="" id="checkbox1" />*/}
            {/*    <label htmlFor="checkbox1">Today's Special</label>*/}
            {/*  </div>*/}

            {/*  <div className="check">*/}
            {/*    <input type="checkbox" name="" id="checkbox2" />*/}
            {/*    <label htmlFor="checkbox2">Dinners</label>*/}
            {/*  </div>*/}
            {/*  <div className="check">*/}
            {/*    <input type="checkbox" name="" id="checkbox3" />*/}
            {/*    <label htmlFor="checkbox3"> Reservation</label>*/}
            {/*  </div>*/}

            {/*  <div className="check">*/}
            {/*    <input type="checkbox" name="" id="checkbox4" />*/}
            {/*    <label htmlFor="checkbox4">Delivery</label>*/}
            {/*  </div>*/}
            {/*</form>*/}

            {/* categories */}

            {/*<div className="cat">*/}
            {/*  <h4 className="cat-head">Categories</h4>*/}
            {/*  <div className="cat--1">*/}
            {/*    <button*/}
            {/*      onClick={() => handleClick("Breakfast")}*/}
            {/*      style={{*/}
            {/*        background: selectedButtons.includes("Breakfast")*/}
            {/*          ? "green"*/}
            {/*          : "white",*/}
            {/*      }}*/}
            {/*    >*/}
            {/*      Breakfast*/}
            {/*    </button>*/}
            {/*    <button*/}
            {/*      onClick={() => handleClick("Brunch")}*/}
            {/*      style={{*/}
            {/*        background: selectedButtons.includes("Brunch")*/}
            {/*          ? "green"*/}
            {/*          : "white",*/}
            {/*      }}*/}
            {/*    >*/}
            {/*      Brunch*/}
            {/*    </button>*/}
            {/*  </div>*/}
            {/*  <button*/}
            {/*    className="cat--2"*/}
            {/*    onClick={() => handleClick("Meal")}*/}
            {/*    style={{*/}
            {/*      background: selectedButtons.includes("Meal")*/}
            {/*        ? "green"*/}
            {/*        : "white",*/}
            {/*    }}*/}
            {/*  >*/}
            {/*    Traditional meals*/}
            {/*  </button>*/}

            {/*  <div className="cat--1">*/}
            {/*    <button*/}
            {/*      onClick={() => handleClick("Lebanese")}*/}
            {/*      style={{*/}
            {/*        background: selectedButtons.includes("Lebanese")*/}
            {/*          ? "green"*/}
            {/*          : "white",*/}
            {/*      }}*/}
            {/*    >*/}
            {/*      Lebanese*/}
            {/*    </button>*/}
            {/*    <button*/}
            {/*      onClick={() => handleClick("Chinese")}*/}
            {/*      style={{*/}
            {/*        background: selectedButtons.includes("Chinese")*/}
            {/*          ? "green"*/}
            {/*          : "white",*/}
            {/*      }}*/}
            {/*    >*/}
            {/*      Chinese*/}
            {/*    </button>*/}
            {/*  </div>*/}
            {/*</div>*/}
            {/* form 2 */}

            {/* last checkbox section */}

            {/*<form action="" style={{ marginTop: 20 }}>*/}
            {/*  <h4 className="suggested">Features</h4>*/}
            {/*  <div className="check">*/}
            {/*    <input type="checkbox" name="" id="checkbox-0" />*/}
            {/*    <label htmlFor="checkbox-0">Child friendly</label>*/}
            {/*  </div>*/}

            {/*  <div className="check">*/}
            {/*    <input type="checkbox" name="" id="checkbox-1" />*/}
            {/*    <label htmlFor="checkbox-1">Pool</label>*/}
            {/*  </div>*/}

            {/*  <div className="check">*/}
            {/*    <input type="checkbox" name="" id="checkbox-2" />*/}
            {/*    <label htmlFor="checkbox-2">Outdoor Seating</label>*/}
            {/*  </div>*/}
            {/*  <div className="check">*/}
            {/*    <input type="checkbox" name="" id="checkbox-3" />*/}
            {/*    <label htmlFor="checkbox-3"> Free Wifi</label>*/}
            {/*  </div>*/}
            {/*</form>*/}
          </div>

          {/* the checkbox at the side ends here */}

          {/* card section in this page */}

          <div className="map-card-section">
            <div className="cardss">
              {/*{cardDataArray.map((data, index) => (*/}
              {/*  <SearchCard*/}
              {/*    key={index}*/}
              {/*    imageSrc={data.imageSrc}*/}
              {/*    title={data.title}*/}
              {/*    address={data.address}*/}
              {/*    phone={data.phone}*/}
              {/*    description={data.description}*/}
              {/*  />*/}
              {/*))}*/}
              {pharmacyData?.map((data, index) => (
                <Link to={`/details/${data.id}`} key={index}>
                  <SearchCard
                    imageSrc={data.cover_image_url || cardDataArray[0].imageSrc} // replace 'default_image_url' with your actual default image url
                    title={data.name}
                    address={data.address}
                    phone={data.phone_number_1}
                    description={"Default Description"} // replace 'Default Description' with your actual default description
                  />
                </Link>
              ))}
            </div>
            {/* card section ends here */}

            {/* map section ends here */}

            <div className="map-container">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3946.2300359536!2d-13.275695224137722!3d8.47699909738354!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xf04c481ad63930d%3A0x956661af6bc1a91b!2sWilkinson%20Road%2C%20Freetown!5e0!3m2!1sen!2ssl!4v1690645005165!5m2!1sen!2ssl"
                className="map"
                height="1347"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
              ></iframe>
            </div>

            {/* map section ends here */}
          </div>
        </div>
      </main>
    </Div>
  );
};

export default Find;

const Div = styled.div`
  margin-top: 5px;

  .login {
    color: black;
  }

  .btn {
    color: black;
  }

  .drop {
    display: flex;
    padding: 10px;
    align-items: flex-start;
    gap: 10px;
    border-radius: 10px;
    border: 1px solid #d9d9d9;
    background: rgba(255, 255, 255, 0);
    margin-top: 0px;
  }

  main {
    margin-top: 2.81rem;
  }

  // .search {
  //   border-radius: 15px;
  //   border: 2px solid #c7db00;
  //   background: #fff;
  // }

  .fill {
    margin-top: 2.81rem;
    display: flex;
    gap: 300px;
    width: 100%;
  }

  .map-card-section {
    display: flex;
    gap: 50px;
    width: 100%;
  }

  .line-up {
    display: flex;
    flex-direction: column;
    margin-left: 2.38rem;
    margin-right: 2.38rem;
  }

  .check {
    display: flex;
    align-items: stretch;
    align-self: stretch;
    background-color: #fff;
    gap: 10px;
  }

  .check label {
    text-align: left;
    color: rgba(0, 0, 0, 0.7);
    font:
      400 20px "Josefin sans",
      sans-serif;
  }

  .suggested {
    color: #000000;
    text-align: left;
    font:
      600 21px "Josefin sans",
      sans-serif;
    text-decoration: underline;
    margin-bottom: 5px;
  }

  input[type="checkbox"] {
    accent-color: black;
  }

  .cat-head {
    color: #000000;
    text-align: left;
    font:
      600 21px "Rubik",
      sans-serif;
    text-decoration: underline;
    margin-top: 41px;
    margin-bottom: 5px;
  }

  .cat--1 {
    display: flex;
    gap: 10px;
  }

  // .cat--1 p:active {
  //   background: red;
  // }

  .cat--1 button {
    color: rgba(0, 0, 0, 0.7);
    text-align: center;
    font:
      400 20px "Josefin sans",
      sans-serif;
    padding: 4px 4px;
    border-radius: 18px;
    border: 1px solid rgba(0, 0, 0, 0.5);
    background: #fff;
    max-width: 199px;
    margin-bottom: 5px;
    margin-top: 5px;
    cursor: pointer;
  }

  .cat--2 {
    color: rgba(0, 0, 0, 0.7);
    text-align: center;
    font:
      400 20px "Josefin sans",
      sans-serif;
    padding: 4px 4px;
    border-radius: 18px;
    border: 1px solid rgba(0, 0, 0, 0.5);
    background: #fff;
    max-width: 206px;
    cursor: pointer;
  }

  cardss {
    gap: 10px;
  }

  .card-header {
    display: flex;
    flex-direction: column;
    max-width: 1000px;
  }

  .map-container {
    width: 100%;
  }

  .map {
    width: 100%;
  }

  .icon {
    color: black;
  }

  .search {
    display: none;
  }

  @media (max-width: 1024px) {
    .fill {
      margin-top: 2.81rem;
      display: flex;
      gap: 50px;
      width: 100%;
    }
  }

  @media (max-width: 765px) {
    .map {
      display: none;
    }

    .line-up {
      display: none;
    }

    // .input1 {
    // border: 2px solid #c7db00;
    // }
    .drop {
      display: none;
    }

    .main-drop {
      display: none;
    }

    .map-container {
      display: none;
    }

    .cardss {
      margin: 20px;
    }

    .search {
      display: block;
      margin: 20px;
    }
  }
`;
