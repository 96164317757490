import styled from "styled-components";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import API_BASE_URL from "../../auth/apiConfig";

const ForgotPasswordForm = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    phone_number: "",
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Phone number submitted", formData.phone_number);

    axios
      .post(`${API_BASE_URL}/user/forget-password/request-otp/`, {
        phone_number: formData.phone_number,
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log("response", response.data.message);
        if (response.data.message === "OTP code sent") {
          // Navigate to the next page
          console.log("OTP sent successfully");
          // navigate("/otp", { state: { from: "/forgotpassword" } });
          // navigate("/resetpassword");
          navigate("/resetpassword", { state: { from: "/forgotpassword" } });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <StyledForm>
      <div className="forgot-password-container">
        <h1 className="forgot-password-heading">Forgot Your Password</h1>
        <div className="input-container">
          <label htmlFor="email">Phone Number</label>
          <div className="">
            <PhoneInput
              name="phone_number"
              country={"us"}
              type="tel"
              className="t"
              value={formData.phone_number}
              onChange={(phone_number) =>
                setFormData({ ...formData, phone_number })
              }
              inputProps={{
                style: { width: 260 }, // Add the style here
                className: "input_field", // You can also apply your class if needed
                placeholder: "Phone", // Placeholder text
              }}
            />
          </div>
        </div>

        <button type="submit" onClick={handleSubmit} className="submit-button">
          Submit
        </button>
      </div>
    </StyledForm>
  );
};

export default ForgotPasswordForm;

const StyledForm = styled.div`
  justify-content: center;
  height: 300px;
  box-shadow:
    0px 106px 42px rgba(0, 0, 0, 0.01),
    0px 59px 36px rgba(0, 0, 0, 0.05),
    0px 26px 26px rgba(0, 0, 0, 0.09),
    0px 7px 15px rgba(0, 0, 0, 0.1),
    0px 0px 0px rgba(0, 0, 0, 0.1);
  border-radius: 11px;
  width: 400px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;

  .forgot-password-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    text-align: center;
  }

  .forgot-password-heading {
    color: #000;
    text-align: center;
    font-family: "Josefin sans", sans-serif;
    font-size: 21px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 10px 0 20px 0;
  }

  .input-container {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    width: 95%;
  }

  .input-container label {
    color: #000;
    font-family: "Josefin sans", sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .input-container input {
    width: 100%;
    border-radius: 19px;
    border: 1px solid rgba(0, 0, 0, 0.5);
    background: #d9d9d9;
    padding: 20px;
    outline: none;
    color: #000;
    font-family: "Josefin sans", sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .submit-button {
    width: 90%;
    padding: 10px 15px;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    background: #c7db00;
    outline: none;
    border: none;
    color: #000;
    text-align: center;
    font-family: "Josefin Sans", sans-serif;
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: 20px;
    cursor: pointer;
  }
`;
