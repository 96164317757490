import styled from "styled-components";
import {useState} from "react";
import axios from "axios";
import AnimatedModalButton from "./ModalButton";
import {useAuth} from "../auth/AuthContext";
import API_BASE_URL from "../auth/apiConfig";

const LikeButton = ({id, like = true, data, setData}) => {
    // const { like, setLike } = useContext(LikeContext);
    const [isLIke, setIsLike] = useState(like);
    const [isOpened, setIsOpened] = useState(false);
    const {
        actions: {isTokenExpired},
    } = useAuth();

    const closeModal = () => {
        setIsOpened(false);
    };

    const token = localStorage.getItem("token");

    console.log("likebu", isLIke);

    // async function handleLike() {
    //     console.log("data: ", data);
    //     if (isTokenExpired()) {
    //         setIsOpened(true);
    //         return;
    //     } else {
    //         setIsOpened(false);
    //     }
    //     try {
    //         if (isLIke) {
    //             // If the place is already marked as favorite, send a DELETE request
    //             const response = await axios.delete(
    //                 `${API_BASE_URL}/place/favorite/delete/${id}`,
    //                 {
    //                     headers: {
    //                         Authorization: `Bearer ${token}`,
    //                     },
    //                 },
    //             );
    //         } else {
    //             // If the place is not marked as favorite, send a POST request
    //             const response = await axios.post(
    //                 `${API_BASE_URL}/place/favorite/add/${id}`,
    //                 {},
    //                 {
    //                     headers: {
    //                         Authorization: `Bearer ${token}`,
    //                     },
    //                 },
    //             );
    //         }
    //
    //         // Change the state only if the request is successful
    //         console.log("isLike : ", isLIke);
    //         setIsLike(!isLIke);
    //         // isLIke = !isLIke;
    //     } catch (error) {
    //         console.error("Error updating favorite status", error);
    //     }
    // }
    async function handleLike() {
        if (isTokenExpired()) {
            setIsOpened(true);
            return;
        } else {
            setIsOpened(false);
        }
        try {
            // Check if the item is already in favorites
            const isAlreadyFavorite = data.find(item => item.place.id === parseInt(id));
            if (isAlreadyFavorite) {
                // If the place is already marked as favorite, send a DELETE request
                await axios.delete(
                    `${API_BASE_URL}/place/favorite/delete/${id}`,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    },
                );
                // setIsLike(false)
            } else {
                // If the place is not marked as favorite, send a POST request
                await axios.post(
                    `${API_BASE_URL}/place/favorite/add/${id}`,
                    {},
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    },
                );
            }

            // Fetch the updated favorites data
            const response = await axios.get(`${API_BASE_URL}/place/favorites`, {
                headers: {
                    Authorization: `Bearer ${localStorage?.getItem("token")}`,
                },
            });
            setData(response?.data);

            // Change the state only if the request is successful
            setIsLike(!isLIke);
        } catch (error) {
            console.error("Error updating favorite status", error);
        }
    }

    return (
        <Div>
            <button onClick={handleLike}>
                <p>favourite</p>
                {data?.length > 0 ? (
                    <img src="/assets/love2.png" width={20}/>
                ) : (
                    <img src="/assets/love1.png" width={20}/>
                )}
            </button>
            {isOpened && (
                <AnimatedModalButton
                    label="Login required"
                    modalOpen={isOpened}
                    errorMessage="Only logged in users can access this."
                    closeModal={closeModal}
                />
            )}
        </Div>
    );
};

export default LikeButton;

const Div = styled.div`
    button {
        height: 45px;
        border-right: 4px solid red;
        border-left: none;
        border-top: none;
        border-bottom: none;
        background: white;
        color: #000000;
        text-align: center;
        font: 500 21px "Josefin Sans",
        sans-serif;
        display: flex;
        align-items: center;
        gap: 5px;
        padding: 10px;
        transition: 0.2s linear;
    }

    button:hover > img {
        transform: scale(1.2);
    }
`;
