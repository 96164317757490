import { styled } from "styled-components";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import axios from "axios";
import API_BASE_URL from "../../auth/apiConfig";

const Otp = () => {
  const { state } = useLocation();
  const [otp, setOtp] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const previousRoute = state?.from;

  console.log("previousRoute", previousRoute);

  // console.log(state);
  // console.log("token", state.token.access_token);

  // function handleVerify() {
  //   setIsLoading(true);
  //   if (state?.token && state?.token?.access_token) {
  //     // Send Axios request to verify the OTP with token bearer header
  //     axios
  //       .post(
  //         "http://44.222.48.171:8080/user/verify-otp/",
  //         {
  //           otp: otp,
  //         },
  //         {
  //           headers: {
  //             Authorization: `Bearer ${state.token.access_token}`,
  //             "Content-Type": "application/json",
  //           },
  //         },
  //       )
  //       .then((response) => {
  //         setIsLoading(false);
  //         console.log("response", response.data.message);
  //         if (response.data.message === "OTP verified successfully") {
  //           // Navigate to the next page
  //           navigate("/mainfavoritepage");
  //         }
  //       })
  //       .catch((error) => {
  //         setIsLoading(false);
  //         console.log(error);
  //       });
  //   }
  // }

  function handleVerify() {
    setIsLoading(true);
    if (
      previousRoute === "/forgotpassword" ||
      (state?.token && state?.token?.access_token)
    ) {
      // Send Axios request to verify the OTP with token bearer header
      axios
        .post(
          `${API_BASE_URL}/user/verify-otp/`,
          {
            otp: otp,
          },
          {
            headers: {
              Authorization: `Bearer ${state?.token?.access_token}`,
              "Content-Type": "application/json",
            },
          },
        )
        .then((response) => {
          setIsLoading(false);
          console.log("response", response.data.message);
          if (response.data.message === "OTP verified successfully") {
            // Navigate to the next page based on the previous route
            if (previousRoute === "/forgotpassword") {
              navigate("/resetpassword");
            } else if (previousRoute === "/signup") {
              navigate("/mainfavoritepage");
            }
          }
        })
        .catch((error) => {
          setIsLoading(false);
          console.log(error);
        });
    } else {
      navigate("/login"); // Redirect to login page if coming from a different route
    }
  }

  const handleInputChange = (e) => {
    setOtp(e.target.value);
  };

  function handleResendOtpAgain() {
    if (state?.token && state?.token?.access_token) {
      // Send Axios request to verify the OTP with token bearer header
      const response = axios
        .get(`${API_BASE_URL}/user/resend-otp/`, {
          headers: {
            Authorization: `Bearer ${state.token.access_token}`,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          console.log("response", response.data.message);
          if (response.data.message === "OTP code resent") {
            console.log("response", response.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
    // const data = response?.data;
    //
    //  if (data.message === "OTP sent successfully") {
    //    console.log("response", data.message);
    //  }else {
    //      console.log("response", data.message);
    //  }
  }

  return (
    <Div>
      <div className="verification-section">
        <h1 className="page-title">Verify Your Account</h1>

        <p className="instructions">
          We have sent you a One Time Password <br /> to your phone number
        </p>

        <p className="instruction-question">
          Did you receive your verification?
        </p>

        <button onClick={handleResendOtpAgain} className="request-again">
          Request Again
        </button>

        <div className="input-container">
          <label htmlFor="otp">Enter OTP</label> <br />
          <input
            type="text"
            id="otp"
            value={otp}
            placeholder="123456"
            className="my-input"
            onChange={handleInputChange}
          />
        </div>

        <button onClick={handleVerify} className="verify-button" type="submit">
          {isLoading ? (
            <div role="status">
              <svg
                aria-hidden="true"
                className="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-yellow-400"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
              <span className="sr-only">Loading...</span>
            </div>
          ) : (
            "Verify"
          )}
        </button>
      </div>

      <div className="image-section">
        <img src="/assets/otp.png" alt="OTP Image" />
      </div>
    </Div>
  );
};

export default Otp;

const Div = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  height: 550px;
  box-shadow:
    0px 106px 42px rgba(0, 0, 0, 0.01),
    0px 59px 36px rgba(0, 0, 0, 0.05),
    0px 26px 26px rgba(0, 0, 0, 0.09),
    0px 7px 15px rgba(0, 0, 0, 0.1),
    0px 0px 0px rgba(0, 0, 0, 0.1);
  border-radius: 11px;
  max-width: 1000px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;

  .page-title {
    color: #000;
    text-align: center;
    font-family: "Josefin sans", sans-serif;
    font-size: 30px;
    font-weight: 700;
    line-height: normal;
    margin-top: 10px;
    margin-bottom: 8px;
  }

  .instructions {
    margin-top: 30px;
    text-align: center;
    color: #000;
    font-family: "Josefin sans", sans-serif;
    font-size: 1.5625rem;
    font-weight: 400;
  }

  .instruction-question {
    text-align: center;
    color: #313030;
    font-family: "josefin sans", sans-serif;
    font-size: 1.3125;
    font-weight: 300;
    margin-top: 30px;
  }

  .request-again {
    text-align: center;
    color: #313030;
    font-family: "josefin sans", sans-serif;
    font-size: 1.3125;
    font-weight: 600;
    margin-top: 0px;
  }

  h3 {
    text-align: center;
    color: #000;
    font-family: rubik;
    font-size: 1.3125;
    font-weight: 600;
  }

  label {
    color: #000;
    text-align: center;
    font-family: "Josefin sans", sans-serif;
    font-size: 21px;
    font-weight: 400;
    line-height: normal;
  }

  .input-container {
    margin-top: 70px;
    margin-left: 10px;
    text-align: center;
  }

  .my-input {
    display: inline-flex;
    padding: 10px 300px 10px 10px;
    align-items: flex-start;
    gap: 10px;
    border: 1px solid #c7db00;
    border-radius: 8px;
    max-width: 378px;
  }

  .verification-section {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 50%;
  }

  .verify-button {
    max-width: 378px;
    padding: 10px 40px;
    text-align: center;
    border-radius: 8px;
    background: #c7db00;
    border: none;
    color: #000;
    text-align: center;
    font-family: "Josefin Sans", sans-serif;
    font-size: 25px;
    font-weight: 700;
    line-height: normal;
    align-self: center;
    cursor: pointer;
  }

  .image-section {
    width: 50%;
  }

  @media (max-width: 765px) {
    margin: 0 auto;
    height: 700px;
    width: 100%;

    .image-section {
      width: 0%;
      display: none;
    }

    .verification-section {
      margin: 0 auto;
      height: auto;
    }

    .my-input {
      padding: 20px 40px 10px 40px;
      align-items: flex-start;
      gap: 10px;
      border: 1px solid #c7db00;
      border-radius: 8px;
      max-width: 208px;
      margin: 20px;
    }
  }
`;
