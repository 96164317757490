// import React from 'react';
// import {Route, Redirect, useNavigate} from 'react-router-dom';
// import { useAuth } from './AuthContext';
//
// const PrivateRoute = ({ component: Component, ...rest }) => {
//     const { state } = useAuth();
//     const navigate = useNavigate();
//
//     return (
//         <Route
//             {...rest}
//             render={props =>
//                 state.isAuthenticated ? (
//                     <Component {...props} />
//                 ) : (
//                     // <Redirect to="/login" />
//                     navigate('/login', { replace: true })
//                 )
//             }
//         />
//     );
// };
//
// export default PrivateRoute;

// import { Outlet, useNavigate } from 'react-router-dom';
// import { useAuth } from './AuthContext';
//
// const PrivateRoute = () => {
//     const { state } = useAuth();
//     const navigate = useNavigate();
//
//     if (!state.isAuthenticated) {
//         navigate('/login');
//         return null;
//     }
//
//     return <Outlet />;
// };
//
// export default PrivateRoute;

import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useAuth } from "./AuthContext";

const PrivateRoute = () => {
  const {
    state,
    actions: { isTokenExpired },
  } = useAuth();
  const navigate = useNavigate();

  console.log(
    "Public routes",
    state.isAuthenticated,
    "isTokenExpired",
    isTokenExpired(),
  );

  useEffect(() => {
    if (isTokenExpired()) {
      navigate("/login");
    }
  }, [navigate, isTokenExpired]);

  if (isTokenExpired()) {
    return null;
  }

  return <Outlet />;
};

export default PrivateRoute;
