// import "./Modal.css";
//
// const EditAccount = () => {
//   return (
//     <div className="modal-content">
//       <span className="edit-account-header">Edit Account</span>
//
//       <div className="main-modal">
//         <div className="input-div">
//           <label htmlFor="">UserName</label>
//           <input type="text" />
//         </div>
//
//         <div className="input-div">
//           <label htmlFor="">Email</label>
//           <input type="email" />
//         </div>
//
//         <div className="gender">
//           <span>Gender</span>
//           <select name="" id="">
//             <option value="">Male</option>
//             <option value="">Female</option>
//           </select>
//         </div>
//         <div className="date-of-birth">
//           <h3>Date of Birth</h3>
//
//           <div>
//             <input type="text" placeholder="date" />
//             <select name="" id="">
//               <option value="">January</option>
//               <option value="">Febrauary</option>
//               <option value="">March</option>
//               <option value="">April</option>
//               <option value="">May</option>
//               <option value="">June</option>
//               <option value="">July</option>
//               <option value="">August</option>
//               <option value="">September</option>
//               <option value="">October</option>
//               <option value="">November</option>
//               <option value="">December</option>
//             </select>
//             <input type="text" placeholder="year" />
//           </div>
//         </div>
//
//         <button className="form-submit-button">Submit</button>
//       </div>
//     </div>
//   );
// };
//
// export default EditAccount;

import React, {useEffect, useState} from "react";
import axios from "axios";
import "./Modal.css";
import PhoneInput from "react-phone-input-2";
import Toast from "../Toast";
import API_BASE_URL from "../../auth/apiConfig";

const EditAccount = () => {
    const [name, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [profile_pic, setProfilePic] = useState("");
    const [date, setDate] = useState("");
    const [month, setMonth] = useState("");
    const [year, setYear] = useState("");
    const [phone_number, setPhoneNumber] = useState(""); // Add this line

    const [toastOpen, setToastOpen] = useState(false);
    const [toastType, setToastType] = useState("");
    const [toastMessage, setToastMessage] = useState("");

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${API_BASE_URL}/user/profile`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                });
                const data = response?.data;
                setUsername(data?.name);
                setEmail(data?.email);
                setProfilePic(data?.profile_pic_url);
                setPhoneNumber(data?.phone_number);
                const dob = new Date(data?.date_of_birth);
                setDate(dob.getDate());
                setMonth(dob.getMonth() + 1);
                setYear(dob.getFullYear());
            } catch (error) {
                console.error(error);
            }
        };

        fetchData();
    }, []);
    const handleSubmit = async (event) => {
        event.preventDefault();

        const data = {
            name,
            email,
            profile_pic: profile_pic,
            date_of_birth: `${year}-${month}-${date}`,
            phone_number, // Add this line
        };

        try {
            const response = await axios.put(
                `${API_BASE_URL}/user/update/profile`,
                data,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                },
            );
            console.log(response.data);
            setToastType("success");
            setToastMessage("Profile updated successfully!");
            setToastOpen(true);
        } catch (error) {
            console.error(error);
            setToastType("error");
            setToastMessage("An error occurred while updating the profile.");
            setToastOpen(true);
        }
    };

    return (
        <div className="modal-content">
            <span className="edit-account-header">Edit Account</span>

            <div className="main-modal">
                <div className="input-div">
                    <label htmlFor="">UserName</label>
                    <input
                        type="text"
                        value={name}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                </div>

                <div className="input-div">
                    <label htmlFor="">Email</label>
                    <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </div>

                <div className="input-div">
                    {" "}
                    {/* Add this block */}
                    <label htmlFor="">Phone Number</label>
                    <PhoneInput
                        country={"us"}
                        value={phone_number}
                        onChange={setPhoneNumber}
                    />
                </div>

                <div className="profile_pic py-2">
                    {/*<span>Profile Picture</span>*/}

                    <input
                        type="file"
                        name="profile_pic"
                        id="profile_pic"
                        onChange={(e) => setProfilePic(e.target.files[0])}
                    />

                    {/*<select*/}
                    {/*  name=""*/}
                    {/*  id=""*/}
                    {/*  value={profile_pic}*/}
                    {/*  onChange={(e) => setProfilePic(e.target.value)}*/}
                    {/*>*/}
                    {/*  <option value="">Male</option>*/}
                    {/*  <option value="">Female</option>*/}
                    {/*</select>*/}
                </div>
                <div className="date-of-birth">
                    <h3>Date of Birth</h3>

                    <div>
                        <input
                            type="text"
                            placeholder="date"
                            value={date}
                            onChange={(e) => setDate(e.target.value)}
                        />
                        <select
                            name=""
                            id=""
                            value={month}
                            onChange={(e) => setMonth(e.target.value)}
                        >
                            <option value="01">January</option>
                            <option value="02">February</option>
                            <option value="03">March</option>
                            <option value="04">April</option>
                            <option value="05">May</option>
                            <option value="06">June</option>
                            <option value="07">July</option>
                            <option value="08">August</option>
                            <option value="09">September</option>
                            <option value="10">October</option>
                            <option value="11">November</option>
                            <option value="12">December</option>
                        </select>
                        <input
                            type="text"
                            placeholder="year"
                            value={year}
                            onChange={(e) => setYear(e.target.value)}
                        />
                    </div>
                </div>

                <button className="form-submit-button" onClick={handleSubmit}>
                    Submit
                </button>
            </div>
            <Toast
                type={toastType}
                message={toastMessage}
                isOpen={toastOpen}
                setIsOpen={setToastOpen}
            />
        </div>
    );
};

export default EditAccount;
